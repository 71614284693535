<template>
  <div class="nav-div">
    <!--导航栏部分HTML代码-->
    <nav id="nav">
      <ul class="options-ul">
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 1 }"
          @click="getstorey(1)"
        >
          首页
        </li>
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 2 }"
          @click="getstorey(2)"
        >
          线上展馆
        </li>
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 3 }"
          @click="getstorey(3)"
        >
          场馆展品
        </li>
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 4 }"
          @click="getstorey(4)"
        >
          场馆活动
        </li>
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 5 }"
          @click="getstorey(5)"
        >
          地震知识
        </li>
        <li
          class="whiteone"
          :class="{ blueone: deliverParentNum === 6 }"
          @click="getstorey(6)"
        >
          参观服务
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  props: {
    // 传递数字
    deliverParentNum: Number
  },
  methods: {
    /* 123456点谁去谁那 */
    getstorey (id) {
      if (id === 1) {
        this.$router.push('/homepage')
      }
      if (id === 2) {
        window.location.assign('http://36.133.7.151:8086/?scene_id=85772471')
      }
      if (id === 3) {
        this.$router.push('/third')
      }
      if (id === 4) {
        this.$router.push('/forth')
      }
      if (id === 5) {
        this.$router.push('/fifth')
      }
      if (id === 6) {
        this.$router.push('/sixth')
      }
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

.nav-div {
  border-bottom: 5Px solid #2e88e4;
  border-top: 1px solid #eaeaea;
}

.options-ul {
  text-align: center;
  margin: 0;
  padding: 0px;
}

nav {
  width: 1300px;
  margin: 0 auto;
}

nav li {
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  cursor: pointer;
  width: 150px;
}

section {
  height: 300px;
}

.whiteone {
  background-color: #fff;
  color: #606060;
  font-size: 16px;
  line-height: 26px;
  height: 26px;
}

.blueone {
  background-color: #2e88e4;
  color: #fff;
  font-size: 16px;
}
</style>
